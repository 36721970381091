import React from 'react';
import JsonModal from '../../../components/json-modal';
import {Message, MessageStatus} from '../api/model';

export const MessageErrorCell: React.FC<{ content: object, message: Message }> = ({
                                                                                      content,
                                                                                      message
                                                                                  }) => {
    if (!content) {
        return null;
    }

    if (message.messageStatus == MessageStatus.SUCCESS) {
        return null;
    }

    return <JsonModal content={content}/>;
};

export default MessageErrorCell;
