import {Dialog} from '@headlessui/react';
import React from 'react';

// @ts-ignore
import XmlBeautify from 'xml-beautify';

const XmlModal: React.FC<{ content: object }> = ({content}) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)} className="dialog">
                <div className="backdrop">
                    <Dialog.Panel className="panel">
                        <pre>{new XmlBeautify().beautify(content,
                            {
                                indent: "  ",  //indent pattern like white spaces
                                useSelfClosingElement: true //true:use self-closing element when empty element.
                            })}</pre>
                    </Dialog.Panel>
                </div>
            </Dialog>
            <span
                className="cursor-pointer"
                onDoubleClick={() => setOpen(true)}
            >{content.toString().substring(0, 30)}...</span>
        </>
    )
        ;
};

export default XmlModal;
