import axios from 'axios';
import type KeycloakType from 'keycloak-js';
import { useAuthStore } from './auth-store';
import { Capabilities } from './types';

interface IntegrationEvent {
  type: 'access_token' | string;
  accessToken?: string;
}

function getAuthConfig(): Promise<Capabilities> {
  return axios.get('/rest/api/system/capabilities').then((d) => d.data);
}

// from https://stackoverflow.com/a/38552302/16058820
function parseJwt(token: string): unknown {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

function setupIntegratedAuth() {
  return new Promise<void>((resolve) => {
    window.addEventListener(
      'message',
      (event: MessageEvent<IntegrationEvent>) => {
        // TODO how can we verify the origin?
        // if (event.origin !== 'http://example.org:8080') return;
        if (event?.data?.type === 'access_token') {
          useAuthStore.setState({
            authenticated: true,
            tokens: {
              accessToken: event.data.accessToken,
            },
            userData: parseJwt(event.data.accessToken!) as any,
          });

          axios.interceptors.request.use((config) => {
            config.headers = {
              Authorization: `Bearer ${event.data.accessToken}`,
            };
            return config;
          });

          resolve();
        }
      },
      false
    );
  });
}

const authConfig = await getAuthConfig();
const keycloakUrl = `${authConfig.auth?.url}/auth`;

const injectedScripts: { [x: string]: Promise<void> } = {};

function injectScript(src: string): Promise<void> {
  if (injectedScripts[src] == null) {
    injectedScripts[src] = new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = () => resolve();
      script.onerror = () => reject();
      script.src = src;
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  }
  return injectedScripts[src];
}
let keycloak: KeycloakType | null = null;

const isIframe =
  new URLSearchParams(window.location.search).get('asIframe') === 'true';

if (isIframe) {
  await setupIntegratedAuth();
} else {
  await injectScript(`${keycloakUrl}/js/keycloak.js`);

  //@ts-ignore
  keycloak = new window.Keycloak({
    clientId: authConfig.auth?.clientId!,
    realm: authConfig.auth?.realm!,
    url: keycloakUrl,
  }) as KeycloakType;

  keycloak.onAuthSuccess = () => {
    console.log('Auth Successfull');
    axios.interceptors.request.use((config) => {
      config.headers = { Authorization: `Bearer ${keycloak!.token}` };
      return config;
    });
  };

  keycloak.onAuthRefreshSuccess = () => {
    console.log('Refresh Successfull');
    axios.interceptors.request.use((config) => {
      config.headers = { Authorization: `Bearer ${keycloak!.token}` };
      return config;
    });
  };

  keycloak.onTokenExpired = () => {
    console.info('Refesh access token!');
    keycloak!
      .updateToken(10)
      .catch((e) => console.error('Error updating token.', e));
  };

  useAuthStore.setState({ inProgress: true });
  const authenticated = await keycloak.init({
    onLoad: 'login-required',
    enableLogging: true,
    pkceMethod: 'S256',
  });

  useAuthStore.setState({
    inProgress: false,
    authenticated,
    tokens: { accessToken: keycloak.token },
  });
}

export default keycloak;
