import React from 'react';
import XmlModal from "../../../components/xml-modal";
import JsonModal from "../../../components/json-modal";

export const ContentCell: React.FC<{ content: object }> = ({content}) => {
    if (!content) {
        return null;
    }
    if (content.toString().startsWith("<")) {

        return <XmlModal content={content}/>;
    }
    return <JsonModal content={content}/>;
};

