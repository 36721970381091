import _ from 'lodash';
import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {SearchValues, useSearchStore} from '../searchStore';
import Pagination from './pagination';

const SearchBar: React.FC = () => {
    const {
        setSearchValues,
        occurred_from,
        occurred_until,
        messages_per_page,
        ...values
    } = useSearchStore();

    const defaultMessagesPerPage = 20;
    useEffect(() => {
        setSearchValues({
            messages_per_page: messages_per_page ?? defaultMessagesPerPage,
        });
    }, []);

    const getFormattedDateWithOffset = (date: Date | undefined) => {
        return date
            ? _.trimEnd(
                new Date(
                    date.getTime() - date.getTimezoneOffset() * 60 * 1000,
                ).toISOString(),
                'Z',
            )
            : undefined;
    };

    const {register, handleSubmit} = useForm<SearchValues>({
        defaultValues: {
            ...values,
            occurred_from: getFormattedDateWithOffset(occurred_from) as any,
            occurred_until: getFormattedDateWithOffset(occurred_until) as any,
        },
    });

    const onSubmit = React.useCallback(handleSubmit(setSearchValues), []);

    return (
        <form onSubmit={onSubmit}>
            <div className="grid grid-cols-4 gap-x-3 gap-y-1 mb-5 items-end">
                <div className="form-control col-span-2">
                    <label htmlFor="messageName">Message Name</label>
                    <input id="messageName" type="text" {...register('message_name')} />
                </div>
                <div className="form-control col-span-2">
                    <label htmlFor="messageSchema">Message Schema</label>
                    <input id="messageSchema" type="text" {...register('message_schema')} />
                </div>
                <div className="form-control col-span-2">
                    <label htmlFor="occurredFrom">Occurred From</label>
                    <input
                        id="occurredFrom"
                        type="datetime-local"
                        {...register('occurred_from', {valueAsDate: true})}
                    />
                </div>
                <div className="form-control col-span-2">
                    <label htmlFor="occurredUntil">Occurred Until</label>
                    <input
                        id="occurredUntil"
                        type="datetime-local"
                        {...register('occurred_until', {valueAsDate: true})}
                    />
                </div>
                <div className="form-control col-span-2">
                    <label htmlFor="direction">Message Direction</label>
                    <select id="direction" {...register('direction')}>
                        <option value="">Select</option>
                        <option value="INCOMING">INCOMING</option>
                        <option value="OUTGOING">OUTGOING</option>
                    </select>
                </div>
                <div className="form-control col-span-2">
                    <label htmlFor="messageStatus">Message Status</label>
                    <select id="messageStatus" {...register('message_status')}>
                        <option value="">Select</option>
                        <option value="SUCCESS">SUCCESS</option>
                        <option value="BATCHING">BATCHING</option>
                        <option value="QUEUED">QUEUED</option>
                        <option value="FAILED">FAILED</option>
                        <option value="FAILED_PERMANENTLY">FAILED_PERMANENTLY</option>
                        <option value="CONFIRMED">CONFIRMED</option>
                    </select>
                </div>
                <div className="form-control col-span-4">
                    <label htmlFor="content">Content Search</label>
                    <input id="content" type="text" {...register('content')} />
                </div>
                <div className="form-control col-span-1">
                    <label htmlFor="messagesPerPage">Messages per Page</label>
                    <select id="messagesPerPage" {...register('messages_per_page')}>
                        <option value={defaultMessagesPerPage}>
                            {defaultMessagesPerPage}
                        </option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="2000">2000</option>
                    </select>
                </div>
                <Pagination register={register}/>
                <div className="col-span-2 h-2/3">
                    <button className="btn primary h-full w-full" type="submit">
                        Search
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SearchBar;
