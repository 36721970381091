import { Dialog } from '@headlessui/react';
import React from 'react';
import { FaTimes } from 'react-icons/fa';

type ThrowError = (error: unknown) => void;

const MappingErrorContext = React.createContext<ThrowError>(null as any);
export const useThrowError = () => React.useContext(MappingErrorContext);

interface MappingErrorBoundaryProps {}
interface MappingErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  open: boolean;
  timeoutId?: number;
}

export default class MappingErrorBoundary extends React.Component<
  React.PropsWithChildren<MappingErrorBoundaryProps>,
  MappingErrorBoundaryState
> {
  constructor(props: MappingErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, open: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error, open: true };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.setState({ hasError: true, error, open: true });
    console.error(error, info);
  }

  openErrorDialog() {
    this.setState({ open: true });
  }

  closeErrorDialog() {
    this.setState({ open: false });
  }

  close() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    this.setState({
      open: false,
      error: undefined,
      hasError: false,
      timeoutId: undefined,
    });
  }

  throwError(error: unknown) {
    setTimeout(() => {
      this.close();
    }, 3000);
    this.setState({ open: true, hasError: true, error: error as Error });
  }

  render() {
    return (
      <MappingErrorContext.Provider value={(e) => this.throwError(e)}>
        <Dialog open={this.state.open} onClose={this.closeErrorDialog}>
          <Dialog.Panel className="z-50 rounded absolute top-5 right-5 bg-red-600 px-5 py-2">
            <div className="relative">
              <FaTimes
                className="text-white absolute -top-1 -right-3 cursor-pointer"
                onClick={this.close}
              />
              <label className="text-white font-semibold ">
                An Error occurred:
              </label>
              <p className="text-white text-lg font-mono">
                {this.state.error?.message}
              </p>
            </div>
          </Dialog.Panel>
        </Dialog>
        {this.props.children}
      </MappingErrorContext.Provider>
    );
  }
}
