import {useAutoAnimate} from '@formkit/auto-animate/react';
import _ from 'lodash';
import React from 'react';
import {useMessageNames} from '../../api/message-api';
import {getMessageDeclaration, useMessageMapping, useUpdateMessageMapping,} from './api/mapping-api';
import AddFieldModal from './components/add-field-modal';
import CommandPreview from './components/command-preview';
import Tree from './components/tree';
import MappingErrorBoundary from './error-boundary';
import {useMappingStore} from './store';
import {flatMapping, insertTreeNode, mapDeclarationToTreeNode,} from './utils/tree.utils';

const Mapping: React.FC = () => {
    const [animationRef] = useAutoAnimate<HTMLDivElement>();
    const {tree, dirty, setTree} = useMappingStore();
    const {data: messageNames} = useMessageNames();
    const [selectedMessage, setSelectedMessage] = React.useState('');
    const {data: messageMapping} = useMessageMapping(selectedMessage);
    const {mutate: updateMapping} = useUpdateMessageMapping(
        messageMapping?.messageName
    );

    const onSave = React.useCallback(() => {
        if (tree) {
            updateMapping(
                flatMapping(tree)
                    .filter((node) => !!node.mapping)
                    .map((node, index) => ({
                        index,
                        optionName: node.mapping!,
                        type: 'NAMED',
                        valuePath: node.key,
                    }))
            );
        }
    }, [tree, updateMapping]);

    React.useEffect(() => {
        if (selectedMessage === '') {
            setTree(undefined);
        }
        const optionName = getMessageDeclaration(messageMapping);
        if (!selectedMessage || !optionName || !messageMapping) {
            return;
        }
        const tree = mapDeclarationToTreeNode(
            '',
            '',
            optionName,
            messageMapping.valueMappings
        );
        const flatTree = flatMapping(tree);
        messageMapping.valueMappings.forEach((mapping) => {
            if (!flatTree.find((node) => node.key === mapping.valuePath))
                insertTreeNode(tree, mapping.valuePath, {
                    children: [],
                    key: mapping.valuePath,
                    name: _.last(mapping.valuePath.split('.'))!,
                    mapping: mapping.optionName,
                    declaration: {type: 'string'},
                });
        });
        setTree(tree);
    }, [setTree, selectedMessage, messageMapping]);

    return (
        <MappingErrorBoundary>
            <div>
                <div className="flex flex-row gap-2 mb-2">
                    <select
                        value={selectedMessage}
                        onChange={(e) => setSelectedMessage(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Select</option>
                        {messageNames?.map((messageName) => (
                            <option key={messageName}>{messageName}</option>
                        ))}
                    </select>
                    <button className="btn primary" disabled={!dirty} onClick={onSave}>
                        Save
                    </button>
                    <AddFieldModal disabled={!selectedMessage}>
                        <button className="btn primary">Add Field</button>
                    </AddFieldModal>
                </div>
                <div className="grid grid-cols-2 gap-5" ref={animationRef}>
                    <div className="max-h-95vh overflow-y-auto">
                        {tree && <Tree node={tree} root/>}
                    </div>
                    {tree && messageMapping && (
                        <CommandPreview tree={tree} commandName={messageMapping.cmdName}/>
                    )}
                </div>
            </div>
        </MappingErrorBoundary>
    );
};

export default Mapping;
