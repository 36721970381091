import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import axios, {AxiosResponse} from 'axios';
import {formatISO} from 'date-fns';
import {addUrlParameters} from '../../../utils/url.utils';
import {useSearchStore} from '../searchStore';
import {Message} from './model';

const unwrap = <T>(res: AxiosResponse<T>): T => res.data;

export const useAllMessages = () => {
    const {
        message_name,
        message_schema,
        direction,
        message_status,
        occurred_from,
        occurred_until,
        content,
        messages_per_page,
        page,
    } = useSearchStore();

    return useQuery(
        [
            'messages',
            message_name,
            message_schema,
            direction,
            message_status,
            occurred_from,
            occurred_until,
            content,
            messages_per_page,
            page,
        ],
        () =>
            axios
                .get<Message[]>(
                    addUrlParameters(`/rest/api/tracing/messages`, {
                        message_name,
                        message_schema,
                        direction,
                        message_status,
                        content,
                        messages_per_page,
                        page,
                        occurred_from: occurred_from && formatISO(occurred_from),
                        occurred_until: occurred_until && formatISO(occurred_until),
                    }),
                )
                .then(unwrap),
        {},
    );
};

export const useRetryFailed = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (messages: Message[]) =>
            axios.post<Message[]>(
                '/rest/api/tracing/retry',
                messages.map((m) => m.messageId),
            ),
        {
            onSuccess() {
                // TODO: Probably  a good idea to do a more performant optimistic update in place & roll back on Error
                queryClient.invalidateQueries(['messages']);
            },
        },
    );
};

export const useMessageCount = () => {
    const {
        message_name,
        message_schema,
        direction,
        message_status,
        occurred_from,
        occurred_until,
        content,
    } = useSearchStore();

    return useQuery(
        [
            'messages',
            message_name,
            message_schema,
            direction,
            message_status,
            occurred_from,
            occurred_until,
            content,
        ],
        () =>
            axios
                .get<number>(
                    addUrlParameters(`/rest/api/tracing/messages/count`, {
                        message_name,
                        message_schema,
                        direction,
                        message_status,
                        content,
                        occurred_from: occurred_from && formatISO(occurred_from),
                        occurred_until: occurred_until && formatISO(occurred_until),
                    }),
                )
                .then(unwrap),
        {},
    );
};
