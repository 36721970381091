import React from 'react';
import {Direction, Message, MessageStatus} from '../api/model';
import {FaPlay} from 'react-icons/fa';
import {useRetryFailed} from '../api/tracing-api';

const ActionCell: React.FC<{ message: Message }> = ({message}) => {
    const {mutate: retryFailed} = useRetryFailed();
    const onClick = React.useCallback(
        () => retryFailed([message]),
        [message.messageId],
    );

    if (
        message.messageStatus === MessageStatus.FAILED ||
        message.messageStatus === MessageStatus.FAILED_PERMANENTLY ||
        message.messageStatus === MessageStatus.CONFIRMED ||
        (
            message.messageStatus === MessageStatus.SUCCESS &&
            message.direction === Direction.OUTGOING &&
            !message.messageName.includes('_SINGLE') &&
            !message.messageName.includes('_BATCHED')
        )
    ) {
        return (
            <button onClick={onClick}>
                <FaPlay className="text-slate-500 hover:text-green-600 cursor-pointer"/>
            </button>
        );
    } else {
        return null;
    }
};
export default ActionCell;
